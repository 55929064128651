<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="cardCouponForm" :model="tableDataAll">
      <div class="module_item">
        <div class="title">关联卡</div>
        <div>
          <el-button class="select_dialog" @click="selectCardFn" type="text">选择卡模版</el-button>
          <el-table
            :data="tableDataAll.tableCardData"
            border
            size="mini"
            style="width: 100%"
            :header-cell-style="{
              borderColor: '#EBEEF5',
              background: '#FAFAFA',
              color: '#333',
              fontWeight: 'normal'
            }">
            <el-table-column prop="cardTemplateName" label="卡模版名称" min-width="180"></el-table-column>
            <el-table-column prop="cardAmount" label="开卡金额（元）" min-width="180"></el-table-column>
            <el-table-column prop="carryingAmount" label="账面金额（元）" min-width="180"></el-table-column>
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteTableFn('card',scope.$index)" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="module_item">
        <div class="title">关联优惠券</div>
        <div>
          <el-button class="select_dialog" @click="selectCouponFn" type="text">选择优惠券</el-button>
          <el-table
            :data="tableDataAll.tableCouponData"
            border
            size="mini"
            style="width: 100%"
            :header-cell-style="{
              borderColor: '#EBEEF5',
              background: '#FAFAFA',
              color: '#333',
              fontWeight: 'normal'
            }">
            <el-table-column prop="activityName" label="优惠券名称" min-width="180"></el-table-column>
            <el-table-column prop="activityDesc" label="优惠内容" min-width="380"></el-table-column>
            <el-table-column prop="remainingQuantity" label="剩余数量" min-width="80"></el-table-column>
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteTableFn('coupon', scope.$index)" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-form>
    <div class="form_btn">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm">保存</el-button>
    </div>
    <!-- 卡模版弹窗 -->
    <el-dialog class="dialog_box" title="选择卡模版" :visible.sync="dialogCardVisible" width="80%" :before-close="cardClose" :close-on-click-modal="false">
      <div class="search_store">
        <div>卡模版名称：</div>
          <el-input placeholder="请输入内容" v-model="dialogSearchForm.cardTemplateName" class="input-with-select">
            <el-button @click="getCardList('input')" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      <el-table
        ref="dialogCardTable"
        v-loading="dialogLoading"
        :data="originalCardList"
        @select="selCardChangeFn"
        border
        size="mini"
        style="width: 100%"
        max-height="410"
        :header-cell-style="{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        }">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="cardTemplateName" label="卡模版名称" min-width="180"></el-table-column>
        <el-table-column prop="cardAmount" label="开卡金额（元）" min-width="180"></el-table-column>
        <el-table-column prop="carryingAmount" label="账面金额（元）" min-width="180"></el-table-column>
        <el-table-column prop="" label="有效期限（天）" min-width="180">
          <template slot-scope="scope">
            <div>{{ scope.row.validPeriod == -1?'永久': (scope.row.validPeriod == -2 ? '截至到 '+scope.row.validTimeEnd : scope.row.validPeriod + '天')  }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page_pagination"
        @size-change="handleSizeCardChange"
        @current-change="handleCurrentCardChange"
        :current-page="pageData.num"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total">
      </el-pagination>
      <div class="dialog_btn">
        <el-button @click="cardConfirm(false)" size="mini">取消</el-button>
        <el-button @click="cardConfirm(true)" type="primary" size="mini">确认</el-button>
      </div>
    </el-dialog>

    <!-- 优惠券弹窗 -->
    <el-dialog class="dialog_box" title="选择优惠券" :visible.sync="dialogCouponVisible" width="80%" :before-close="couponClose" :close-on-click-modal="false">
      <div class="search_store">
        <div>选择门店：</div>
        <el-select v-model="dialogSearchForm.storeCode" 
          filterable
          clearable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="getStoreList"
          :loading="loading"
          @change="getCouponList('input')">
          <el-option
            v-for="(item,index) in storeList"
            :key="index"
            :label="item.storeName"
            :value="item.storeCode">
          </el-option>
        </el-select>
      </div>
      <el-table
        ref="dialogCouponTable"
        v-loading="dialogLoading"
        :data="originalCouponList"
        @select="selCouponChangeFn"
        border
        size="mini"
        style="width: 100%"
        max-height="410"
        :header-cell-style="{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        }">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="activityName" label="优惠券名称" min-width="120"></el-table-column>
        <el-table-column prop="activityDesc" label="优惠内容" min-width="380"></el-table-column>
        <el-table-column prop="validPeriod" label="有效期限（天）" min-width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.validPeriod==-1">永久</div>
            <div v-else-if="scope.row.validPeriod==-2">{{ scope.row.activityBegin }} 至 {{ scope.row.activityEnd }}</div>
            <div v-else>{{ scope.row.validPeriod }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="activityStatus" label="状态" min-width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.activityStatus=='01'">未开始</span>
            <span v-if="scope.row.activityStatus=='04'">进行中</span>
            <span v-if="scope.row.activityStatus=='05'">已结束</span>
            <span v-if="scope.row.activityStatus=='07'">已停发</span>
          </template>
        </el-table-column>
        <el-table-column prop="remainingQuantity" label="剩余数量" min-width="80"></el-table-column>
      </el-table>
      <el-pagination
        class="page_pagination"
        @size-change="handleSizeCouponChange"
        @current-change="handleCurrentCouponChange"
        :current-page="pageData.num"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total">
      </el-pagination>
      <div class="dialog_btn">
        <el-button @click="couponConfirm(false)" size="mini">取消</el-button>
        <el-button @click="couponConfirm(true)" type="primary" size="mini">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";

// 售卖价格验证方法
const checkIsPositive =(rule, value, callback)=> {
  if(!value){
    callback()
  } else {
    const reg = /^\d+(?=\.{0,1}\d+$|$)/
    if (reg.test(value)){
      callback()
    } else {
      callback(new Error('价格需大于等于0'))
    }
  }
}
export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  // @marketing.Action queryCardTemplateList;
  // @management.Action getStoreList
  // @management.Action getStoreCouponList

  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        isAdd: null, //是否为添加
        formData: {
          packageName: '',                        //套餐名称
          inventory: '',                          //发放总量
          categoryCode: '',                       //场景
          state: 1,                               //是否上架  1-上架 2-下架
          desc: '',                               //使用规则
          price: '',                              //金额
          integral: '',                           //积分
          detailImageUrl: "",//详情图
          bannerUrl: ""//banner图
        },
        sceneList: [],                            //场景值
        tableDataAll: {
          tableCardData: [],                        //已选卡模版
          tableCouponData: [],                      //已选优惠券
        },

        selectCardData: [],                         //选择的卡数据
        selectCouponData: [],                       //选择的优惠券数据

        pageData: {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        },
        dialogLoading: false,                       //弹窗加载
        dialogCardVisible: false,                    //卡模版弹窗
        dialogCouponVisible: false,                  //优惠券弹窗
        originalCardList: [],                     //原卡模版列表
        originalCouponList: [],                   //原优惠券列表
        dialogSearchForm: {
          storeCode: '',
          cardTemplateName: ''
        },                       //弹窗筛选数据
        storeList: [],                              //门店列表
        loading: false,
        defaultBannerList: [],
        defaultDetailList: [],
        defaultThumbnailList: [],
        activityCode: '',                     //活动code
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "裂变活动", path: "/management/fission/fission-campagin" },
        {
          name: "配置卡券奖励"
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 卡模版，优惠券反显
    backDisplayFn(type){
      if(type == 'card'){
        let selectCardData = this.selectCardData;
        let originalCardList = this.originalCardList;
        if(selectCardData.length>0){
          selectCardData.forEach(item => {
            originalCardList.forEach(ele => {
              if(item.cardTemplateCode == ele.cardTemplateCode){
                this.$refs.dialogCardTable.toggleRowSelection(ele);
              }
            })
          })
        }
      }
      if(type == 'coupon'){
        let selectCouponData = this.selectCouponData;
        let originalCouponList = this.originalCouponList;
        if(selectCouponData.length>0){
          selectCouponData.forEach(item => {
            originalCouponList.forEach(ele => {
              if(item.activityCode == ele.activityCode){
                this.$refs.dialogCouponTable.toggleRowSelection(ele);
              }
            })
          })
        }
      }
    },
    // 选择卡模版
    selectCardFn(){
      this.pageData = {                                 //分页数据
        num: 1,
        size: 10,
        total: 0
      },
      this.getCardList().then(res =>{
        this.selectCardData = JSON.parse(JSON.stringify(this.tableDataAll.tableCardData))
        this.backDisplayFn('card')
      });
     
      this.dialogCardVisible = true;
    },
    // 卡模版翻页
    handleCurrentCardChange(e){
      this.pageData.num = e;
      this.getCardList().then(res =>{
        this.backDisplayFn('card')
      });
     
    },
    // 卡模版选择每页条数
    handleSizeCardChange(e){
      this.pageData.size = e;
      this.getCardList().then(res =>{
        this.backDisplayFn('card')
      });
     
    },
    // 卡模版弹窗按钮操作
    cardConfirm(type){
      if(type){
        if(this.selectCardData.length>0){
          this.tableDataAll.tableCardData = JSON.parse(JSON.stringify(this.selectCardData));
          this.dialogCardVisible = false;
        }else{
          this.$message.warning('请选择套餐卡')
        }
      }else{
        this.selectCardData = [];
        this.dialogCardVisible = false;
      }
    },
    // 卡模版弹窗关闭按钮
    cardClose(done){
      done();
      this.selectCardData = [];
    },
    // 卡模版弹窗列表checked change
    selCardChangeFn(rows, row){
      let selectCardData = this.selectCardData;       //确认选择前暂存数据字段
      let isChecked = rows.some(item => item.cardTemplateCode == row.cardTemplateCode); //判断是选中还是取消
      if(isChecked){
        selectCardData.push(row);
      }else{
        this.selectCardData = selectCardData.filter(item => item.cardTemplateCode != row.cardTemplateCode);  //去掉取消选中的数据
      }
    },


    // 选择优惠券
    selectCouponFn(){
      this.pageData = {                                 //分页数据
        num: 1,
        size: 10,
        total: 0
      }
      this.dialogCouponVisible = true;
      if(this.dialogSearchForm.storeCode){
        this.getCouponList().then(res =>{
          this.selectCouponData = JSON.parse(JSON.stringify(this.tableDataAll.tableCouponData))
          this.backDisplayFn('coupon')
        });
      }
    },
    // 优惠券翻页
    handleCurrentCouponChange(e){
      this.pageData.num = e;
      this.getCouponList().then(res =>{
        this.backDisplayFn('coupon')
      });
     
    },
    // 优惠券选择每页条数
    handleSizeCouponChange(e){
      this.pageData.size = e;
      this.getCouponList().then(res =>{
        this.backDisplayFn('coupon')
      });
     
    },
    // 优惠券弹窗按钮操作
    couponConfirm(type){
      if(type){
        if(this.selectCouponData.length>0){
          this.tableDataAll.tableCouponData = JSON.parse(JSON.stringify(this.selectCouponData));
          this.dialogCouponVisible = false;
        }else{
          this.$message.warning('请选择优惠券')
        }
      }else{
        this.selectCouponData = [];
        this.dialogCouponVisible = false;
      }
    },
    // 优惠券弹窗关闭按钮
    couponClose(done){
      done();
      this.selectCouponData = [];
    },
    // 优惠券弹窗列表checked change
    selCouponChangeFn(rows, row){
      let selectCouponData = this.selectCouponData;       //确认选择前暂存数据字段
      let isChecked = rows.some(item => item.activityCode == row.activityCode); //判断是选中还是取消
      if(isChecked){
        selectCouponData.push(row);
      }else{
        this.selectCouponData = selectCouponData.filter(item => item.activityCode != row.activityCode);  //去掉取消选中的数据
      }
    },

    // 已选列表删除
    deleteTableFn(type, index){
      if(type == 'card'){
        this.$confirm('是否删除此卡?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableDataAll.tableCardData.splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
      if(type == 'coupon'){
        this.$confirm('是否删除此优惠券?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableDataAll.tableCouponData.splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
      console.log(index)
    },

    // 重构套餐卡优惠券
    resetSku(){
      let a = [];
      let b = [];
      let cardList = this.tableDataAll.tableCardData;
      let couponList = this.tableDataAll.tableCouponData;
      cardList.forEach(item => {
        a.push({
          price: item.price,
          referencePrice: item.cardAmount,
          skuId: item.cardTemplateCode,
          skuName: item.cardTemplateName,
          skuNum: item.skuNum,
          skuType: 1,
          description: item.storeTypesDesc? item.storeTypesDesc.join(",") :''
        })
      })
      couponList.forEach(item => {
        b.push({
          price: item.price,
          referencePrice: item.price,
          skuId: item.activityCode,
          skuName: item.activityName,
          skuNum: item.skuNum,
          skuType: 2,
          tenantCode: item.tenantCode,
          orgCode: item.orgCode,
          description: item.activityStoresDesc
        })
      })
      return a.concat(b);
    },

    // 表单保存
    saveForm(){
      if(this.tableDataAll.tableCardData.length>0||this.tableDataAll.tableCouponData.length>0){
        let rewardCardList = this.tableDataAll.tableCardData;
        let rewardCouponList = this.tableDataAll.tableCouponData;
        this.$store.dispatch('management/postCardPackageAwardSave',{
          activityCode: this.activityCode,
          rewardCardList,
          rewardCouponList
        }).then(res => {
          this.$message.success('保存成功！')
          this.$router.back()
        })
      }else{
        this.$message.warning('请至少选择一张套餐卡或优惠券！')
      } 
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },

    // 获取卡模版列表
    async getCardList(type=''){
      if(type == 'input'){
        this.pageData = {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        }
      }
      this.dialogLoading = true;
      await this.$store.dispatch('marketing/queryCardTemplateList',{
        pageNum: this.pageData.num,
        pageSize: this.pageData.size,
        cardTemplateName: this.dialogSearchForm.cardTemplateName,
        enableStatus: 1,
      }).then(res => {
        this.originalCardList = res.data.list || [];
        this.pageData.total = res.data.total;
        this.dialogLoading = false;
      })
    },

    // 获取门店列表
    async getStoreList(val){
      console.log(val)
      await this.$store.dispatch('management/getStoreList',{
        storeTypeList: ['3', '8', '9'],
        pageNum: 1,
        pageSize: 10,
        storeName: val
      }).then(res => {
        this.loading = false
        this.storeList = res.data.list || [];
        // this.dialogSearchForm.storeCode = this.storeList[0].storeCode,
        // this.getCouponList();
      })
    },
    // 获取优惠券列表
    async getCouponList(type=''){
      if(type == 'input'){
        this.pageData = {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        }
      }
      if(!this.dialogSearchForm.storeCode){
        this.getStoreList();
        this.originalCouponList = [];
        return
      }
      this.dialogLoading = true;
      await this.$store.dispatch('management/getStoreCouponList',{
        storeCode: this.dialogSearchForm.storeCode||'',
        pageNum: this.pageData.num,
        pageSize: this.pageData.size
      }).then(res => {
        this.originalCouponList = res.data.list;
        this.pageData.total = res.data.total;
        this.dialogLoading = false;
      })
    },

    async getPackageInfo(){
      this.$store.dispatch('management/getCardPackageAwardInfo',{
        activityCode: this.activityCode
      }).then(res => {
        //套餐卡
        this.tableDataAll.tableCardData = res.data.rewardCardList;
        //优惠券
        this.tableDataAll.tableCouponData = res.data.rewardCouponList
      })
    },

    handleUserView(row) {
      let { href } = this.$router.resolve({
        path: `/customer/customer-view/${row.customerMobile}`
      });
      window.open(href, "_blank");
    }

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取门店列表
    this.getStoreList();
    this.activityCode = this.$route.query.activityCode;
    // 获取详情
    this.getPackageInfo();
  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .module_item{
      background: #fff;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .title{
        font-weight: bold;
      }
      .select_dialog{
        color: #ff9500;
        padding: 0;
      }
      .form_box{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        .form_item{
          width: 40%;
          flex-shrink: 0;
          box-sizing: border-box;
          .el-input{
            width: 80%;
          }
        }
        .form_item_price{
          display: flex;
          justify-content: flex-start;
          .el-input{
            width: 100px;
          }
          span{
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
</style>
